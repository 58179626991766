import React from 'react';
import './App.css';
import mapboxgl from 'mapbox-gl';
import DateTime from 'luxon/src/datetime.js'

mapboxgl.accessToken = 'pk.eyJ1IjoiZ2RlZm9lc3QiLCJhIjoiY2tqcm1iYzcyMHA2bTJycWg0cTB6bnJ3ZSJ9.B0lKqP0MscWGswAsTyTEYw';

class  App extends React.Component {

    constructor(props) {
        super(props);
        this.state = {last_update: undefined};
    }

    componentDidMount() {

        // Simple GET request using fetch
        fetch('https://apiwaze.guidel.app/api/metadata')
        .then(response => response.json())
        .then(data => this.setState({ last_update: DateTime.fromISO(data.last_update) }));

        const map = new mapboxgl.Map({
            container: this.mapContainer,
            style: 'mapbox://styles/mapbox/dark-v10',
            center: [4.38, 50.83],
            zoom: 10
        });

        // disable map rotation using right click + drag
        map.dragRotate.disable();
        
        // disable map rotation using touch rotation gesture
        map.touchZoomRotate.disableRotation();
    
        map.on('load', function () {
            // Add a geojson point source.
            // Heatmap layers also work with a vector tile source.
            map.addSource('police', {
                'type': 'geojson',
                'data':
                    'https://apiwaze.guidel.app/api/data.geojson'
            });
    
            map.addLayer(
                {
                    'id': 'police-heat',
                    'type': 'heatmap',
                    'source': 'police',
                    'paint': {
                        // Increase the heatmap weight based on frequency and property magnitude
                        'heatmap-weight': [
                            'interpolate',
                            ['linear'],
                            ['get', 'mag'],
                            0,
                            0,
                            10,
                            1
                        ]
                    }
                },
                'waterway-label'
            );
        });
    }

    render() {
        return (
            <div>
                <div ref={el => this.mapContainer = el} className="mapContainer" />
                <div className="textUpdate"> Last update: {this.state.last_update ? this.state.last_update.toFormat('DDD HH:mm') : "Fetching..."} </div>
            </div>
        )
    }
}

export default App;
